const __pages_import_0__ = () => import("/src/scenes/stats.vue");
const __pages_import_1__ = () => import("/src/scenes/spiral.vue");
const __pages_import_2__ = () => import("/src/scenes/rose.vue");
const __pages_import_3__ = () => import("/src/scenes/level.vue");
const __pages_import_4__ = () => import("/src/scenes/index.vue");
const __pages_import_5__ = () => import("/src/scenes/donut.vue");
const __pages_import_6__ = () => import("/src/scenes/cross.vue");

const routes = [{"name":"stats","path":"/stats","component":__pages_import_0__,"props":true},{"name":"spiral","path":"/spiral","component":__pages_import_1__,"props":true,"title":"Electric","subtitle":"by Chromatone"},{"name":"rose","path":"/rose","component":__pages_import_2__,"props":true},{"name":"level","path":"/level","component":__pages_import_3__,"props":true,"title":"Electric","subtitle":"by Chromatone"},{"name":"index","path":"/","component":__pages_import_4__,"props":true,"title":"Floating","subtitle":"by Chromatone"},{"name":"donut","path":"/donut","component":__pages_import_5__,"props":true},{"name":"cross","path":"/cross","component":__pages_import_6__,"props":true}];

export default routes;